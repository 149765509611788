import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { Order } from '../models/order';
import { Guestreg } from '../models/guestreg';
import { Table } from '../components/ordito-shared/shared_models/table';
import { Dish } from '../components/ordito-shared/shared_models/dish';

import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { RestaurantOwner } from '../models/restaurantOwner';
import { Restaurant } from '../components/ordito-shared/shared_models/restaurant';


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    tablesRef: AngularFirestoreCollection<Table>;

    constructor(private db: AngularFirestore) {
        this.tablesRef = this.db.collection<Table>('tables');
    }

    getOrders(restaurantID: string) {
        return this.db.collection('orders/' + restaurantID + '/orders').snapshotChanges().pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Order;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }

    getNewOrders(restaurantID: string) {
        return this.db.collection('orders/' + restaurantID + '/orders').stateChanges(['added']).pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Order;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }

    orderDone(restaurantID: string, order: Order) {
        delete order.open;
        order.disabled = true;
        order.doneDate = Timestamp.fromDate(new Date());

        this.db.doc('orders/' + restaurantID + '/orders/' + order.id).update({...order});
    }

    deleteOrder(restaurantID: string, order: Order) {
        order.deleted = true;
        this.db.doc('orders/' + restaurantID + '/orders/' + order.id).update({...order});
    }

    getGuestregs(restaurantID: string) {
        return this.db.collection('guestRegistration/' + restaurantID + '/guestRegistration').snapshotChanges().pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Guestreg;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }
    getNewGuestregs(restaurantID: string) {
        return this.db.collection('guestRegistration/' + restaurantID + '/guestRegistration').stateChanges(['added']).pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Guestreg;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }

    guestregDone(restaurantID: string, guestreg: Guestreg) {
        delete guestreg.open;
        guestreg.disabled = true;
        guestreg.doneDate = Timestamp.fromDate(new Date());

        this.db.doc('guestRegistration/' + restaurantID + '/guestRegistration/' + guestreg.id).update({...guestreg});
    }

    deleteGuestreg(restaurantID: string, guestreg: Guestreg) {
        guestreg.deleted = true;
        this.db.doc('guestRegistration/' + restaurantID + '/guestRegistration/' + guestreg.id).update({...guestreg});
    }

    getTables(restaurantDocumentID: string) {
        // console.log("getTables", restaurantDocumentID);
        return this.db.collection('tables', ref => ref.where('restaurantDocumentID', '==', restaurantDocumentID.trim()))
            .snapshotChanges().pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Table;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }

    getTableById(id: string) {
        return this.tablesRef.doc(id).snapshotChanges();
    }

    updateTable(table: Table) {
        // TODO geht des so?
        this.tablesRef.doc(table.id).update(table);
    }

    addTable(table: Table) {
        // console.log(table);
        this.tablesRef.add(table);
    }

    deleteTable(id: string) {
        this.tablesRef.doc(id).delete();
    }

    tableCodeExists(code: string) {
        return this.db.collection('tables', ref => ref.where('tableCode', '==', code)).get().pipe(map(data => {
            return data.size > 0;
        })).toPromise();
    }

    public createMenu(menu: any) {
        this.db.collection('menus').add(menu);
    }

    getMenu(restaurantID: string) {
        return this.db.collection('menus-client-app/' + restaurantID + '/dishes').snapshotChanges().pipe(map(actions => {
            return actions.map(action  => {
                const data = action.payload.doc.data() as Dish;
                const id = action.payload.doc.id;
                return { id, ...data };
            });
          })
        );
    }

    updateDish(restaurantID: string, d: Dish) {
        return this.db.collection('menus-client-app/' + restaurantID + '/dishes').doc(d.id).update(d);
    }

    // get all Restaurants from user id
    getRestaurantsIds(uid: string) {
        return this.db.collection('restaurants', ref => ref.where('restaurantOwners', 'array-contains', uid))
            .snapshotChanges().pipe(map(actions => {
            return actions.map(action => {
                return action.payload.doc.data();
            });
        }));
    }

    addRestaurantOwner(owner: RestaurantOwner) {
        return this.db.collection('restaurantOwners').doc(owner.uid).set(owner);
    }

    getRestaurantOwner(restaurantOwnerID: string) {
        return this.db.collection('restaurantOwners').doc(restaurantOwnerID).get();
    }

    addRestaurant(restaurant: Restaurant) {
        return this.db.collection('restaurants').add(restaurant);
    }

    getRestaurant(restaurantID: string) {
        return this.db.collection('restaurants').doc(restaurantID).get().pipe(map(data => {
            return data.data() as Restaurant;
        }
        ));
    }

    updateRestaurant(restaurant: Restaurant) {
        return this.db.collection('restaurants').doc(restaurant.id).update(restaurant);
    }

    updateLoginHistory(restaurantOwnerID: string, loginHistory) {
        // console.log(loginHistory);
        // return;
        this.db.collection('restaurantOwners').doc(restaurantOwnerID).update({loginHistory}).then(data => {
            // console.log('update', data);
        });
    }

}
