import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastService } from './toast.service';
import { ApiService } from './api.service';
import { RestaurantOwner } from '../models/restaurantOwner';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState: any;

  constructor(
    private storage: Storage,
    private router: Router,
    private plt: Platform,
    public afAuth: AngularFireAuth,
    private toastService: ToastService,
    private apiService: ApiService,
    private translate: TranslateService
    ) {

      this.plt.ready().then(() => {
        // console.log("check token", sessionStorage.getItem('user'));
      });

  }

  public async login(email, password) {
    return await this.afAuth.auth.signInWithEmailAndPassword(email.trim(), password).then(res => {
      const uid = res.user.uid;

      // email not varified
      if (!res.user.emailVerified) {
        console.log('email not verified');
        return { status: -1, user: res };
      }

      if (uid !== '') {

        this.apiService.getRestaurantsIds(uid).subscribe(data => {
          if (data.length > 0) {
            // TODO können ja auch mehrere sein...
            const r = data[0];

            this.apiService.getRestaurantOwner(uid).subscribe(d => {
              const loginHistory = d.data().loginHistory;
              loginHistory.push({date: new Date()});
              this.apiService.updateLoginHistory(uid, loginHistory);
            });

            res.user.getIdToken().then(token => {
              sessionStorage.setItem('token', token);
            });

            sessionStorage.setItem('user', uid);
            sessionStorage.setItem('email', res.user.email);
            sessionStorage.setItem('restaurantID', r['id']);
            sessionStorage.setItem('restaurantName', r['name']);
            this.router.navigate(['admin', 'orders']);
            this.toastService.showToast(this.translate.instant('Auth.LOGIN_SUCCEEDED'));

          } else {
            this.toastService.showToast(this.translate.instant('Auth.NO_RESTAURANT_LINKED'));
            return -2;
          }
        });

      }
      }).catch(error => {
        console.log(error);
        this.toastService.showToast(this.translate.instant('Auth.LOGIN_FAILED'));
        return -3;
    });

  }

  public async logout() {
    await this.afAuth.auth.signOut().then(() => {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('restaurantID');
      sessionStorage.removeItem('restaurantName');
      this.router.navigate(['/']);
      this.toastService.showToast(this.translate.instant('Auth.LOGOUT_SUCEEDED'));
    }).catch(error => {});
  }

  public async register(email: string, password: string) {
    return await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(resultOfRegister => {
        this.verifyEmail(resultOfRegister);
        return {
          uid: resultOfRegister.user.uid,
          email: resultOfRegister.user.email
        };
    });
  }

  public async verifyEmail(userCredentials: firebase.auth.UserCredential) {
    console.log('sendEmailVerification');
    userCredentials.user.sendEmailVerification();
  }

}
