import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private jwtHelper = new JwtHelperService();

  constructor(private authService: AuthenticationService) { }

  canActivate(): boolean {
    if (sessionStorage.getItem('user') === null || this.jwtHelper.isTokenExpired(sessionStorage.getItem('token'))) {
      this.authService.logout();
      // this.router.navigate(['/']);
    } else {
      return true;
    }
  }

}
