import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './components/public/login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './components/public/register/register.module#RegisterPageModule' },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    loadChildren: './components/admin/admin.module#AdminPageModule',
  },
  { path: 'guestreg', loadChildren: './components/admin/guestreg/guestreg.module#GuestregPageModule' },
  { path: 'guestreg-archive',
    loadChildren: './components/admin/guestreg/guestreg-archive/guestreg-archive.module#GuestregArchivePageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
