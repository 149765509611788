import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import { firestore } from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.initializeApp();
    this.translate.addLangs(['en', 'de', 'nl']);
    this.translate.setDefaultLang('de');
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de|nl/) ? browserLang : 'de');
    localStorage.setItem('language', this.translate.currentLang);
    /*this.translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : translate.defaultLang);
    */
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // console.log("environment", environment.firebase.projectId);
      // const now = firestore.Timestamp.fromDate(new Date());
      // console.log(now, 'firestore timestamp');
      // console.log('converted timestamp to millis: ', now.toMillis())
      // console.log(Date.now(), 'dateobject default');

      // this.authService.authenticationState.subscribe((state) => {
      //   if (state) {
      //     this.router.navigate(['admin', 'guestreg', 'archive'], { skipLocationChange: true });
      //   } else {
      //     this.router.navigate(['login']);
      //   }
      // });

    });
  }
}
